import { defineStore } from "pinia";

export const useMainStore = defineStore("store", {
  // Define the state
  state: () => ({
    intervalId: null,
    breadcrumbs: [],
    showMobileNavigation: false,
    fileTypes: [],
    subcategories: [],
    periods: [],
    ticketCategories: [],
    chatHistory: [],
  }),

  actions: {
    updateCrumbs(crumbs) {
      this.breadcrumbs = crumbs;
    },
    async updateChatHistory() {
      const strapiClient = useStrapiClient();
      this.chatHistory = await strapiClient("/organization-chat/history");
    },
    async initialize() {
      const strapi = useStrapi();
      const strapiClient = useStrapiClient();
      
      await strapiClient("/organization-chat/init");
      this.chatHistory = await strapiClient("/organization-chat/history");
    },
  },
});
